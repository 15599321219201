import React from "react";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

import { StyledComponentsBreakpoints } from "./Layout/Responsive";

import { FEST_ADS_SMALL, FEST_ADS_LARGE } from "../constants/constants";

class AdComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    const SMALL_ADS = FEST_ADS_SMALL.map(ad => {
      return Object.assign(ad, {
        src: require(`../assets/ads/${ad.image_url}`)
      });
    });
    const LARGE_ADS = FEST_ADS_LARGE.map(ad => {
      return Object.assign(ad, {
        src: require(`../assets/ads/${ad.image_url}`)
      });
    });

    this.setState({
      SMALL_ADS,
      LARGE_ADS
    });
  }

  componentDidMount() {
    const SMALL_ADS = FEST_ADS_SMALL.map(ad => {
      return Object.assign(ad, {
        src: require(`../assets/ads/${ad.image_url}`)
      });
    });
    const LARGE_ADS = FEST_ADS_LARGE.map(ad => {
      return Object.assign(ad, {
        src: require(`../assets/ads/${ad.image_url}`)
      });
    });
    const SMALL_AD_VARIANT = Math.floor(Math.random() * SMALL_ADS.length);
    const LARGE_AD_VARIANT = Math.floor(Math.random() * LARGE_ADS.length);
    const SMALL_AD = SMALL_ADS[SMALL_AD_VARIANT];
    const LARGE_AD = LARGE_ADS[LARGE_AD_VARIANT];

    this.setState({
      smallAdName: SMALL_AD.name,
      smallAdUrl: SMALL_AD.url,
      smallAdImageUrl: SMALL_AD.image_url,
      smallAdSrc: SMALL_AD.src,
      largeAdName: LARGE_AD.name,
      largeAdUrl: LARGE_AD.url,
      largeAdImageUrl: LARGE_AD.image_url,
      largeAdSrc: LARGE_AD.src
    });
  }

  render() {
    const { size, style } = this.props;
    const {
      smallAdName,
      smallAdUrl,
      smallAdImageUrl,
      largeAdName,
      largeAdUrl,
      largeAdImageUrl,
      smallAdSrc,
      largeAdSrc
    } = this.state;
    return (
      <AdContainer>
        {size === "small" && (
          <AdImageContainer style={style} key={smallAdName}>
            <a href={smallAdUrl} rel="noopener " target="_blank">
              <img src={smallAdSrc} alt={smallAdName} title={smallAdName} />
            </a>
          </AdImageContainer>
        )}
        {size === "large" && (
          <AdImageContainer style={style} key={largeAdName}>
            <a href={largeAdUrl} rel="noopener " target="_blank">
              <img src={largeAdSrc} alt={largeAdName} title={largeAdName} />
            </a>
          </AdImageContainer>
        )}
      </AdContainer>
    );
  }
}

const fadeInAnimation = keyframes`${fadeIn}`;

const AdContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};
  margin: 1rem auto;
  text-align: center;
  max-width: 900px;

  ${StyledComponentsBreakpoints.Phone` 
    margin: 1rem auto;
  `};

  img {
    transition: filter 311ms ease;
  }
`;

const AdImageContainer = styled.div`
  ${StyledComponentsBreakpoints.Phone` 
      img {
        max-width: 90%;
      }
    }
  `};
`;

export default AdComponent;
