import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import siteConfig from "../../../data/SiteConfig";

import { FONT_FAMILIES, COLORS } from "../../utils/theme";

import { RADIO_PLAYER_HEIGHT } from "../Radio/RadioPlayer";

import { StyledComponentsBreakpoints } from "./Responsive";

import { FullWidthContainer } from "./ContentContainers";
import Button from "../Forms/Button";

const iconColor = "#808285";

class Footer extends Component {
  render() {
    return (
      <FullWidthContainer>
        <FooterWrapper>
          <FooterComponent>
            <FestInfoContainer>
              <LogoContainer>
                <img
                  alt="The FEST est. 2002 logo"
                  src="/images/logos/fest_main_logo.png"
                />
              </LogoContainer>
              <FooterCopyright>{siteConfig.copyright}</FooterCopyright>
              <ButtonContainer>
                <Button
                  variant="flat"
                  onClick={() => {
                    window.open("https://twitter.com/thefestfl", "_blank");
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "twitter"]}
                      color={iconColor}
                    />
                    {" "}
                  </ExternalLink>
                </Button>

                <Button
                  variant="flat"
                  onClick={() => {
                    window.open("https://facebook.com/thefestfl", "_blank");
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "facebook"]}
                      color={iconColor}
                    />
                    {" "}
                  </ExternalLink>
                </Button>

                <Button
                  variant="flat"
                  onClick={() => {
                    window.open("https://instagram.com/thefestfl", "_blank");
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "instagram"]}
                      color={iconColor}
                    />
                    {" "}
                  </ExternalLink>
                </Button>
                <Button
                  variant="flat"
                  onClick={() => {
                    window.open(
                      "https://flickr.com/photos/thefestfl/collections/",
                      "_blank"
                    );
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "flickr"]}
                      color={iconColor}
                    />
                  </ExternalLink>
                </Button>
              </ButtonContainer>
            </FestInfoContainer>

            <CreditsContainer>
              <FooterCredit>
                THEFESTFL.COM DESIGN, DEVELOPMENT, HOSTING
                <br />
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://twitter.com/stephanwozniak"
                >
                  STEVE WOZNIAK
                </a>
                {" ("}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://chill.biz"
                >
                  <img
                    srcSet="/images/logos/chilluminati-hellvetica-logo-white.png 1x,
                    /images/logos/chilluminati-hellvetica-logo-white@2x.png 2x"
                    alt="CHILLUMINATI LLC"
                    
                  />
                </a>
                )
              </FooterCredit>
              <br />
              <FooterCredit>
                FEST 22 ARTWORK
                {" "}
                <br />
                <a
                  target="_blank"
                  rel=" noreferrer noopener"
                  href="https://www.thevnm.com/"
                >
                  RICHARD MININO (HORSEBITES)
                </a>
              </FooterCredit>
            </CreditsContainer>
          </FooterComponent>
        </FooterWrapper>
      </FullWidthContainer>
    );
  }
}

const FooterWrapper = styled.footer`
  /*
  padding-bottom: ${RADIO_PLAYER_HEIGHT + 16}px;
  */
  margin-top: 36px;
  min-height: 4px;

  background-color: rgba(35, 31, 32, 0.9);
  background: ${COLORS.black.hex};

`;

const FooterComponent = styled.div`
  color: ${COLORS.white.rgba};

  a {
    color: ${COLORS.white.rgba};
  }

  margin: 0 auto;
  padding: 18px 0 18px;
  max-width: 1024px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

const FestInfoContainer = styled.div`
  order: 1;
  flex: 1 0 200px;
  padding-left: 16px;


  text-align: center;

  ${StyledComponentsBreakpoints.Phone` 
    flex: 1 0 100%;
    padding-left: 0;
    padding-bottom: 16px;
  `};
`;
const CreditsContainer = styled.div`
  order: 2;
  flex: 1 0 auto;
  padding-right: 16px;
  min-width: 400px;
  text-align: center;
  padding-bottom: ${RADIO_PLAYER_HEIGHT + 16}px;

  img {
    height: 13px;
  }

  ${StyledComponentsBreakpoints.Phone`
    text-align: left;
    flex: 1 0 100%;
    min-width: 100%;
    width: 100%;
    
    padding-left: 16px;
    padding-right: 16px;

    font-size: 11px;

    img {
      height: 11px;    
    }
  `};
`;

const LogoContainer = styled.div`
  img {
    max-width: 182px;
  }
`;
const FooterCopyright = styled.span`
  ${FONT_FAMILIES.headline};

  font-size: 12px;

  color: ${COLORS.lightgrey.hex};
`;

const FooterCredit = styled.span`
  color: ${COLORS.lightgrey.hex};
  display: block;
  line-height: 21px;
  font-size: 14px;


  ${StyledComponentsBreakpoints.Phone`
  text-align: center;
    font-size: 0.8rem;
    line-height: 1.6rem;
  `};

  a {
    color: ${COLORS.lightgrey.hex};
    font-weight: 800;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 12px;
  button {
    margin: 0 !important;
    padding: 4px 8px !important;
    min-width: 0 !important;

    &:hover {
      background: none;
    }
  }
`;

const ExternalLink = styled.div`
  svg {
    height: 24px !important;
    width: 24px !important;
    margin-right: -6px;
  }

  font-size: 1rem;
  text-decoration: none;
  line-height: 1.6;

  ${StyledComponentsBreakpoints.Phone`
    svg {
      height: 24px;
      width: 24px;
    }
  `};
`;

export default Footer;
