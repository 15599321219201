import React from "react";

import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FullWidthContainer,
  FixedWidthContainer
} from "./Layout/ContentContainers";
import { StyledComponentsBreakpoints } from "./Layout/Responsive";

import Button from "./Forms/Button";

const iconColor = '#eee';

const getDaysFromToday = (year, month, day) => {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);

  const futureDay = new Date();
  futureDay.setFullYear(year);
  futureDay.setMonth(month - 1);
  futureDay.setDate(day);
  futureDay.setHours(0);
  futureDay.setMinutes(0);
  futureDay.setSeconds(0);

  const timeDiff = futureDay.getTime() - now.getTime();
  const diffDays = Math.round(timeDiff / (1000 * 60 * 60 * 24));

  return diffDays;
};

class SocialMediaComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysLeft: getDaysFromToday(2024, 10, 25)
    };
  }

  render() {
    const { daysLeft } = this.state;

    return (
      <FullWidthContainer>
        <SocialMediaContainer>
          <FixedWidthContainer>
            <FlexContainer>
              <CopyContainer>
                <span>
                  {daysLeft > 1 && (<span>{daysLeft} DAYS TO @THEFESTFL! #FEST22</span>)}
                  {daysLeft === 1 && (<span>{daysLeft} DAY TO @THEFESTFL! #FEST22</span>)}
                  {daysLeft < 1 && (<span>TAG US @THEFESTFL IN YR PHOTOS & VIDEOS! #FEST22</span>)}
                </span>
              </CopyContainer>
              <ButtonContainer>
                <Button
                  variant="flat"
                  onClick={() => {
                    window.open("https://twitter.com/thefestfl", "_blank");
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "twitter"]}
                      color={iconColor}
                    />
                    {" "}
                  </ExternalLink>
                </Button>

                <Button
                  variant="flat"
                  onClick={() => {
                    window.open("https://facebook.com/thefestfl", "_blank");
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "facebook"]}
                      color={iconColor}
                    />
                    {" "}
                  </ExternalLink>
                </Button>

                <Button
                  variant="flat"
                  onClick={() => {
                    window.open("https://instagram.com/thefestfl", "_blank");
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "instagram"]}
                      color={iconColor}
                    />
                    {" "}
                  </ExternalLink>
                </Button>
                <Button
                  variant="flat"
                  onClick={() => {
                    window.open(
                      "https://flickr.com/photos/thefestfl/collections/",
                      "_blank"
                    );
                  }}
                >
                  <ExternalLink>
                    <FontAwesomeIcon
                      icon={["fab", "flickr"]}
                      color={iconColor}
                    />
                  </ExternalLink>
                </Button>
              </ButtonContainer>
            </FlexContainer>
          </FixedWidthContainer>
        </SocialMediaContainer>
      </FullWidthContainer>
    );
  }
}

const fadeInAnimation = keyframes`${fadeIn}`;

const SocialMediaContainer = styled.div`
  margin: 0;
  padding: 8px 0;
  display: block;
  
  
  animation: 0.420s ${fadeInAnimation};
  span {
    color: #eee;
    font-size: 20px !important;

    text-transform: uppercase;
    font-family: "Rubik", "League Gothic", "Rubik", -apple-system, BlinkMacSystemFont,
      "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, "segoe ui",
      arial, sans-serif;
      font-feature-settings: "kern";
  }

  ${StyledComponentsBreakpoints.Phone`
    span {
    }
  `};
`;

const FlexContainer = styled.div`
  margin: 0 auto;
  text-align: center;

  min-height: 48px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const CopyContainer = styled.div`
  flex: 1 1 60%;
  order: 1;
`;
const ButtonContainer = styled.div`
  flex: 1 1 40%;
  order: 2;
  min-width: 240px;

  button {
    margin: 0 !important;
    padding: 4px 8px !important;
    min-width: 0 !important;

    &:hover {
      background: none;
    }
  }
`;

const ExternalLink = styled.div`
  svg {
    height: 24px !important;
    width: 24px !important;
    margin-right: 2px;
  }

  color: rgba(6, 6, 6, 1);
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "League Gothic", "Rubik", -apple-system, BlinkMacSystemFont,
    "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, "segoe ui",
    arial, sans-serif;
  line-height: 1.6;

  ${StyledComponentsBreakpoints.Phone`
    svg {
      height: 24px;
      width: 24px;
    }
  `};
`;

export default SocialMediaComponent;
