import React from "react";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

import { FullWidthContainer } from "./ContentContainers";

import { StyledComponentsBreakpoints } from "./Responsive";

import {
  FEST_SPONSORS_TIER_ONE,
  FEST_SPONSORS_TIER_TWO,
  FEST_SPONSORS_TIER_THREE
} from "../../constants/constants";

const SponsorsComponent = props => {
  const { tier } = props;
  return (
    <Sponsors>
      <FullWidthContainer>
        <SponsorList>
          {tier === 1 &&
            FEST_SPONSORS_TIER_ONE.map(sponsor => {
              return (
                <SponsorListItem key={sponsor.name} className="sponsorsTierOne">
                  <a
                    href={sponsor.url}
                    rel="noopener"
                    target="_blank"
                  >
                    <img
                      src={require(`../../assets/sponsors/${
                        sponsor.imageFilename
                      }`)}
                      alt={sponsor.name}
                      title={sponsor.name}
                    />
                  </a>
                </SponsorListItem>
              );
            })}
          {tier === 2 &&
            FEST_SPONSORS_TIER_TWO.map(sponsor => (
              <SponsorListItem key={sponsor.name} className="sponsorsTierTwo">
                <a href={sponsor.url} rel="noopener" target="_blank">
                  <img
                    src={require(`../../assets/sponsors/${
                      sponsor.imageFilename
                    }`)}
                    alt={sponsor.name}
                    title={sponsor.name}
                  />
                </a>
              </SponsorListItem>
            ))}
          {tier === 3 &&
            FEST_SPONSORS_TIER_THREE.map(sponsor => {
              return (
                <SponsorListItem
                  key={sponsor.name}
                  className="sponsorsTierThree"
                >
                  <a
                    href={sponsor.url}
                    rel="noopener"
                    target="_blank"
                  >
                    <img
                      src={require(`../../assets/sponsors/${
                        sponsor.imageFilename
                      }`)}
                      alt={sponsor.name}
                      title={sponsor.name}
                    />
                  </a>
                </SponsorListItem>
              );
            })}
        </SponsorList>
      </FullWidthContainer>
    </Sponsors>
  );
};

const fadeInAnimation = keyframes`${fadeIn}`;

const Sponsors = styled.div`
  animation: 0.420s ${fadeInAnimation};
  margin: 0 auto 1rem;
  text-align: center;
  max-width: 900px;

  ${StyledComponentsBreakpoints.Phone` 
    margin: 0 auto;
  `};

  img {
    filter: invert(16%);
    transition: filter 311ms ease;

    ${StyledComponentsBreakpoints.Phone` 
      filter: invert(0%);
    `};

    &:hover {
      filter: invert(0%) !important;
    }
  }
`;
const SponsorList = styled.ul`
  list-style-type: none;
`;

const SponsorListItem = styled.li`
  display: inline-block;

  &.sponsorsTierOne {
    margin: 8px 4px 8px;
    img {
      max-height: 64px;
    }
  }
  &.sponsorsTierTwo {
    margin: 0 6px;
    img {
      max-height: 60px;
    }
  }
  &.sponsorsTierThree {
    margin: 0 4px;
    img {
      max-height: 54px;
      filter: invert(24%);
    }
  }

  ${StyledComponentsBreakpoints.Phone` 
    img { 
      filter: invert(0%); 
    }


    &.sponsorsTierOne {
      line-height: 1.2rem;
      img {
        max-height: 30px;
      }
    }
    &.sponsorsTierTwo {
      margin: 0 2px;
      img {
        max-height: 48px;
      }
    }
    &.sponsorsTierThree {
      img {
        max-height: 40px;
      }
    }
  `};
`;

export default SponsorsComponent;
