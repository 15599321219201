export const PERFORMER_TYPE_SLUG_MAP = {
  band: "bands",
  wrestler: "fest-wrestling",
  podcast: "podcasts",
  comedian: "comedy"
};
export const PERFORMER_TYPE_PAGENAME_MAP = {
  band: "Band Lineup",
  wrestler: "FEST Wrestling",
  podcast: "Podcast Lineup",
  comedian: "Comedy Showcase"
};

export const FEST_SPLASH_PERFORMERS_TIER_ONE = [
  { label: "Hot Water Music", url: "/bands/hot-water-music", subtext: `30 YEAR ANNIVERSARY CELEBRATION!` },
  { label: "The Bouncing Souls", url: "/bands/the-bouncing-souls", subtext: `TWO SETS! FOUR ALBUMS!!!`, twoSets: true },
  { label: "Streetlight Manifesto", url: "/bands/streetlight-manifesto", subtext: `TWO SETS!`, twoSets: true },
  { label: "Matt and Kim", url: "/bands/matt-and-kim" },
  { label: "Superchunk", url: "/bands/superchunk" },
  { label: "Joyce Manor", url: "/bands/joyce-manor", twoSets: true },
  { label: "Ted Leo and The Pharmacists", url: "/bands/ted-leo-and-the-pharmacists" },
  { label: "Cloud Nothings", url: "/bands/cloud-nothings" },
  { label: "Touché Amoré", url: "/bands/touche-amore", twoSets: true },
  { label: "Cursive", url: "/bands/cursive" },
  { label: "The Flatliners", url: "/bands/the-flatliners" },
  { label: "Cobra Skulls", url: "/bands/cobra-skulls" },
  { label: "Mustard Plug", url: "/bands/mustard-plug" },
  { label: "American Steel", url: "/bands/american-steel" },
  { label: "SCREAM", url: "/bands/scream" },
  { label: "Soul Glo", url: "/bands/soul-glo" },
  { label: "You Blew It!", url: "/bands/you-blew-it" },
  { label: "Big D and the Kids Table", url: "/bands/big-d-and-the-kids-table", twoSets: true },
  { label: "A Wilhelm Scream", url: "/bands/a-wilhelm-scream", twoSets: true },
  { label: "Riverboat Gamblers", url: "/bands/riverboat-gamblers", twoSets: true },
  { label: "The Planet Smashers", url: "/bands/the-planet-smashers", twoSets: true },
  { label: "The Copyrights", url: "/bands/the-copyrights", twoSets: true },
  { label: "The Dopamines", url: "/bands/the-dopamines", twoSets: true },
  { label: "Bad Cop/Bad Cop", url: "/bands/bad-cop-bad-cop", twoSets: true },
  { label: "Further Seems Forever", url: "/bands/further-seems-forever" },
  { label: "Mean Jeans", url: "/bands/mean-jeans", twoSets: true },
  { label: "Chris Gethard", url: "/comedy/chris-gethard", twoSets: true },
  { label: "Pageninetynine", url: "/bands/pageninetynine" },
  { label: "City of Caterpillar", url: "/bands/city-of-caterpillar" },
  { label: "Chuck Ragan and the Camaraderie", url: "/bands/chuck-ragan-and-the-camaraderie" },
  { label: "Tim Barry", url: "/bands/tim-barry" },
  { label: "Brendan Kelly", url: "/bands/brendan-kelly" },

];
export const FEST_SPLASH_PERFORMERS_TIER_TWO = [
  { label: "Restorations", url: "/bands/restorations", twoSets: true },
  { label: "Broadway Calls", url: "/bands/broadway-calls", twoSets: true },
  { label: "Kill Lincoln", url: "/bands/kill-lincoln", twoSets: true },
  { label: "Doc Hopper", url: "/bands/doc-hopper" },
  { label: "Gameface", url: "/bands/gameface", twoSets: true },
  { label: "Single Mothers", url: "/bands/single-mothers" },
  { label: "Dikembe", url: "/bands/dikembe" },
  { label: "Candy Hearts", url: "/bands/candy-hearts" },
  { label: "Walter Mitty and His Makeshift Orchestra", url: "/bands/walter-mitty-and-his-makeshift-orchestra" },
  { label: "MakeWar", url: "/bands/makewar", twoSets: true },
  { label: "War on Women", url: "/bands/war-on-women" },
  { label: "J. Robbins Band", url: "/bands/j-robbins-band" },
  { label: "The Bollweevils", url: "/bands/the-bollweevils" },
  { label: "Assholeparade", url: "/bands/assholeparade" },
  { label: "Tiltwheel", url: "/bands/tiltwheel" },
  { label: "Codefendants", url: "/bands/codefendants" },
  { label: "Codeseven", url: "/bands/codeseven" },
  { label: "Rozwell Kid", url: "/bands/rozwell-kid" },
  { label: "Chastity", url: "/bands/chastity" },
  { label: "Apes of the State", url: "/bands/apes-of-the-state" },
  { label: "Zeta", url: "/bands/zeta" },
  { label: "PG14", url: "/bands/pg14" },
  { label: "Annabel", url: "/bands/annabel" },
  { label: "Prawn", url: "/bands/prawn" },
  { label: "Dollar Signs", url: "/bands/dollar-signs" },
  { label: "Caravels", url: "/bands/caravels" },
  { label: "Omnigone", url: "/bands/omnigone" },
  { label: "Wolf-Face", url: "/bands/wolf-face" },
  { label: "Bad Operation", url: "/bands/bad-operation" },
  { label: "The Doped Up Dollies", url: "/bands/the-doped-up-dollies" },
  { label: "Snodgrass & Buddies", url: "/bands/snodgrass-and-buddies" },
  { label: "The Penske File", url: "/bands/the-penske-file" },
  { label: "Mikey Erg", url: "/bands/mikey-erg" },
  { label: "Bad Luck.", url: "/bands/bad-luck" },
  { label: "The Iron Roses", url: "/bands/the-iron-roses" },
  { label: "Ways Away", url: "/bands/ways-away" },
  { label: "Gillian Carter", url: "/bands/gillian-carter" },
];

export const FEST_SPLASH_PERFORMERS_SPECIAL_SETS = [
  { label: "The Bouncing Souls", url: "/bands/the-bouncing-souls", subtext: `"Hopeless Romantic" & "The Gold Record" & "How I Spent My Summer Vacation" & "Anchors Aweigh"` },
  { label: "Joyce Manor", url: "/bands/joyce-manor", subtext: `"Never Hungover Again"` },
  { label: "Ted Leo and The Pharmacists", url: "/bands/ted-leo-and-the-pharmacists", subtext: `"Shake the Sheets"` },
  { label: "Cloud Nothings", url: "/bands/cloud-nothings", subtext: `"Here and Nowhere Else"` },
  //{ label: "American Steel", url: "/bands/american-steel", subtext: `"Rogue's March" 25th Anniversary` },
  { label: "Big D and the Kids Table", url: "/bands/big-d-and-the-kids-table", subtext: `"Good Luck"` },
  { label: "You Blew It!", url: "/bands/you-blew-it", subtext: `"Keep Doing What You're Doing"` },
  { label: "Doc Hopper", url: "/bands/doc-hopper", subtext: `"Aloha"` },
  { label: "Gameface", url: "/bands/gameface", subtext: `"Every Last Time"` },
  { label: "Single Mothers", url: "/bands/single-mothers", subtext: `"Negative Qualities"` },
  { label: "Dikembe", url: "/bands/dikembe", subtext: `"Mediumship"` },
  { label: "Candy Hearts", url: "/bands/candy-hearts", subtext: `"All The Ways You Let Me Down"` },
  { label: "Walter Mitty and his Makeshift Orchestra", url: "/bands/walter-mitty-and-his-makeshift-orchestra", subtext: `"Well Soon"` },
];

export const FEST_SPONSORS_TIER_ONE = [
  {
    name: "SmartPunk",
    url: "http://www.smartpunk.com",
    imageFilename: "smartpunk.png",
  },
  {
    name: "Visit Gainesville",
    url: "https://www.visitgainesville.com/",
    imageFilename: "visitgainesville.png"
  },
  {
    name: "Pabst Blue Ribbon",
    url: "https://www.pabstblueribbon.com/",
    imageFilename: "pbr.png"
  },
  {
    name: "Ludwig Drums",
    url: "https://www.ludwig-drums.com/",
    imageFilename: "ludwig.png"
  },
  {
    name: "Orange Amplification",
    url: "https://orangeamps.com/",
    imageFilename: "orange.png"
  },
  /*
  {
    name: "Commonwealth Press",
    url: "http://cwpress.com",
    imageFilename: "cwp.png"
  },

*/
  /*
{
name: "Madison Drum Makers",
url: "https://www.facebook.com/MadisonDrumMakers/",
imageFilename: "mdm.png"
},

  {
    name: "Pabst Blue Ribbon",
    url: "http://pabstblueribbon.com",
    imageFilename: "pbr.png"
  },
  {
    name: "Meltdown Creative Works",
    url: "https://www.meltdowncreative.com",
    imageFilename: "meltdown.png"
  },
 
*/
];

export const FEST_SPONSORS_TIER_TWO = [
  {
    name: "Liquid Death",
    url: "http://liquiddeath.com",
    imageFilename: "liquiddeath.png",
  },
  {
    name: "Stupid Rad Merch Company",
    url: "https://stupidradmerch.com/",
    imageFilename: "stupidrad.png",
  },
  {
    name: "Iodine Records",
    url: "https://iodinerecords.com/",
    imageFilename: "iodine.png",
  },
  {
    name: "Paramount Grill",
    url: "https://www.paramountgrill.com/",
    imageFilename: "paramountgrill.png"
  },
  {
    name: "Wunderland Tattoo",
    url: "https://www.wunderlandtattoo.com",
    imageFilename: "wunderland.png"
  },
  {
    name: "New Noise Magazine",
    url: "https://newnoisemagazine.com",
    imageFilename: "newnoise.png",
  },
  {
    name: "Loosey's",
    url: "http://looseys.com/",
    imageFilename: "looseys.png",
  },
  {
    name: "Tullamore D.E.W. Whiskey",
    url: "https://www.tullamoredew.com/en-us/",
    imageFilename: "tullamore.png"
  },
  {
    name: "Anthem Tattoo",
    url: "https://www.anthemtattoo.com",
    imageFilename: "anthemtattoo.png"
  },
  /*
{
  name: "Silver Q",
  url: "https://www.silverqbilliards.com",
  imageFilename: "silverq.png"
},
{
  name: "White Claw",
  url: "https://www.whiteclaw.com/",
  imageFilename: "whiteclaw.png"
},

/* 
{
  name: "Left of the Dial",
  url: "https://www.leftofthedial.fm",
  imageFilename: "leftofthedial.png"
},
{
  name: "Pizza Eaters",
  url: "https://www.pizza-eaters.com",
  imageFilename: "pizzaeaters.png"
},

{
  name: "Pure Noise Records",
  url: "https://www.purenoise.net",
  imageFilename: "purenoise.png"
},
{
  name: "Paper + Plastick Records",
  url: "https://www.paperandplastick.com/",
  imageFilename: "paperplastick.png"
},
{
  name: "Alliance Tattoo Supply",
  url: "https://alliancetattoosupply.com/",
  imageFilename: "alliancetattoosupply.png"
},
{
  name: "Stick It To Me Buttons",
  url: "http://www.stickittomebuttons.com/",
  imageFilename: "stickit.png"
},
{
  name: "Curaleaf",
  url: "https://www.curaleaf.com/",
  imageFilename: "curaleaf.png"
},
{
  name: "City of Gainesville",
  url: "https://www.gainesvillefl.gov/Home",
  imageFilename: "gainesville.png"
},
{
  name: "Gainesville Community Reinvestment Area",
  url: "https://gainesvillecra.com/",
  imageFilename: "gnvcra.png"
}, */
];

export const FEST_SPONSORS_TIER_THREE = [
  {
    name: "CHILLUMINATI",
    url: "https://www.chill.biz",
    imageFilename: "chilluminati.png"
  },
  {
    name: "Montgomery Drive",
    url: "https://www.montgomerydrive.com",
    imageFilename: "montgomerydrive.png"
  },
  {
    name: "Feed the Scene",
    url: "https://www.feedthescene.com",
    imageFilename: "fts.png",
  },
  {
    name: "Sinking Ship Records",
    url: "https://www.sinkingshiprecords.com/",
    imageFilename: "sinkingship.png",
  },
  {
    name: "Sell The Heart Records",
    url: "https://selltheheartrecords.com/",
    imageFilename: "selltheheart.png",
  },
  {
    name: "Side One Dummy Records",
    url: "https://sideonedummy.com/",
    imageFilename: "sideonedummy.png",
  },
  {
    name: "Killer Guitar Rigs",
    url: "https://killerguitarrigs.com/",
    imageFilename: "killerguitarrigs.png",
  },
  {
    name: "Equal Vision Records",
    url: "https://equalvision.com",
    imageFilename: "equalvision.png",
  },
  {
    name: "Dave Decker Photography",
    url: "https://linktr.ee/davedeckerphotography",
    imageFilename: "davedecker.png",
  },
  {
    name: "Dry Wrought Cider",
    url: "https://drywrought.com/",
    imageFilename: "drywrought.png",
  },
  {
    name: "Little Wing Studio",
    url: "https://littlewingstudios.com/",
    imageFilename: "littlewing.png",
  },
  {
    name: "Meltdown Creative",
    url: "https://www.meltdowncreative.com/",
    imageFilename: "mcw.png"
  }
  /*
  {
    name: "Havoc Underground",
    url: "https://www.havocunderground.com/",
    imageFilename: "havoc.png",
  },
  {
    name: "DCxPC Live",
    url: "https://dcxpclive.com",
    imageFilename: "dcxpc.png",
  },
  {
    name: "Bad Time Records",
    url: "https://www.badtimerecords.com/",
    imageFilename: "badtimerecords.png"
  },
  {
    name: "iloveimprint.com",
    url: "https://iloveimprint.com",
    imageFilename: "imprint.png",
  },
  {
    name: "The Shirt Series",
    url: "https://theshirtseries.com/",
    imageFilename: "theshirtseries.png",
  },
  {
    name: "Tervis",
    url: "https://www.tervis.com/",
    imageFilename: "tervis.png",
  },

  {
    name: "Cruise Records",
    url: "https://cruiserecords.de/",
    imageFilename: "cruiserecords.png",
  },
  {
    name: "New Belgium Brewing",
    url: "https://www.newbelgium.com/",
    imageFilename: "newbelgium.png"
  },
  {
    name: "Twisted Tea",
    url: "https://www.twistedtea.com/",
    imageFilename: "twistedtea.png"
  },
  {
    name: "Opus Coffee",
    url: "https://opuscoffee.com/",
    imageFilename: "opuscoffee.png",
  },
  {
    name: "Bragging Rights",
    url: "https://braggingrights.com/",
    imageFilename: "braggingrights.png",
  },

  

  /*
  {
    name: "Chunksaah",
    url: "https://chunksaah.com/",
    imageFilename: "chunksaah.png",
  },
  {
    name: "Sofaburn",
    url: "https://www.sofaburn.com/",
    imageFilename: "sofaburn.png"
  },
  {
    name: "Refresh Records",
    url: "https://www.refreshrecs.com/",
    imageFilename: "refresh.png"
  },
  {
    name: "Mom's OG",
    url: "https://www.facebook.com/momsog1017/",
    imageFilename: "momsog.png"
  }
  */
];

export const FEST_ADS_LARGE = [
  {
    name: "Gainesville Regional Airport",
    url: "http://flygainesville.com",
    image_url: "original-gnv_fest_webad_728x90.png",
  },
  {
    name: "Chilluminati",
    url: "https://chill.biz",
    image_url: "Chilluminati-thefest19-728x90.gif",
  },
  {
    name: "Say-10 Records",
    url: "https://www.say-10.com",
    image_url: "Say-10_FESTad_728x90.gif",
  },
  /* 
  {
    name: "NerdyKeppie",
    url: "https://nerdykeppie.com/collections/summer-festival-collection",
    image_url: "NerdyKeppie-Leaderboard-QuiteAlright-728x90.gif",
  },
  */
];

export const FEST_ADS_SMALL = [
  {
    name: "Gainesville Regional Airport",
    url: "http://flygainesville.com",
    image_url: "original-gnv_fest_webad_300x100.png",
  },
  {
    name: "Chilluminati",
    url: "https://chill.biz",
    image_url: "Chilluminati-thefest19-300x100.gif",
  },
  /* 
  {
    name: "Nerdy Keppie",
    url: "https://nerdykeppie.com/collections/summer-festival-collection",
    image_url: "NerdyKeppie-Feature-QuiteAlright-300x100.png",
  },
  */
];
