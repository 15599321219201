import styled from "styled-components";

export const FullWidthContainer = styled.div`
  width: 100%;
`;

export const FixedWidthContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  padding: 0 36px;

  @media (max-width: 767px) {
    padding: 0 24px;
  }
`;
