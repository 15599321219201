import React from "react";
import { connect } from "react-redux";

import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "gatsby";

import { FullWidthContainer } from "./ContentContainers";
import { StyledComponentsBreakpoints } from "./Responsive";

const NoticeComponent = () => (
  <React.Fragment />
);
const ActiveNoticeComponent = () => (
  <FullWidthContainer>
    <NoticeContainer>
      <FlexContainer>
        <CopyContainer>
        <FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" />{' '}The FEST 23: October 24 - 26th, 2025<FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" /><br />
{/* 
<FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" />{' '}The FEST 22 APP IS OUT!!!<FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" /><br />
Download it now:{" "}<a
  href="https://apps.apple.com/us/app/fest-22/id6633415374"
  rel="noopener noreferrer"
  target="_blank">iPhone</a> / <a
    href="https://play.google.com/store/apps/details?id=com.chilluminati.fest22"
    rel="noopener noreferrer"
    target="_blank">Android</a>

<br /><br />
          <FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" />
          {' '}FEST 22 PASSES ARE SOLD OUT!{' '}
          <FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" />
          <br /><a target="_blank" href="https://thefestfl.limitedrun.com/categories/hotels">LIMITED OFFICIAL HOTEL AVAILABILITY!!!</a><br /><br />
          <Link to="/what-is-fest">IS THIS YOUR FIRST FEST? START HERE!</Link><br />
          <br /><br />


          <a target="_blank" href="https://wl.seetickets.us/thefest">SINGLE SHOWCASE TICKETS ON SALE NOW!</a><br />(SELECT SHOWS ONLY WITH VERY LIMITED AVAILABILITY)
          <br />

    */}

          {/* 
          <br />
          <a target="_blank" href="https://wl.seetickets.us/event/KICKOFF-SHOW-Bo-Diddley-Plaza/598497?afflky=TheFest">10/24 KICK-OFF SHOW TIX @ BO DIDDLEY (SEETICKETS.US)</a><br />
          */}

          {/*
            
            <br />
            <a target="_blank" href="https://pillowheadmerch.com/collections/the-fest-merch">LAST CALL TO BUY FEST 21 MERCH!</a>
            <br /><br />
                THE FEST 21 T-SHIRT PREORDER ENDS<br />NOV 20TH AT 12PM EASTERN!!!
            <FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" />{' '}The FEST 21 APP IS OUT!!!<FontAwesomeIcon icon={["fa", "bullhorn"]} color="#fff" /><br />
            Download it now:{" "}<a
              href="https://apps.apple.com/us/app/fest-21/id6459265079"
              rel="noopener noreferrer"
              target="_blank">iPhone</a> / <a
                href="https://play.google.com/store/apps/details?id=com.chilluminati.fest21"
                rel="noopener noreferrer"
                target="_blank">Android</a>
            <br />
                <br />
                FEST 21 <a target="_blank" href="https://www.Seetickets.us/TheFest21">SINGLE SHOW TICKETS</a> ON SALE NOW!<br /><br />
                FEST 21 3-DAY PASSES ARE SOLD OUT!!!<br />
    
                <a target="_blank" href="https://twitter.com/thefestfl/status/1659023904403337217">READ THIS TO SEE HOW YOU CAN STILL ATTEND</a><br />
            */}


          {/* 
            THURSDAY KICKOFF PARTY TICKETS ALSO ON SALE:<br />
            <a target="_blank" href="https://www.seetickets.us/event/Bo-Diddley-Plaza-Descendents-Into-It-Over-It-and-more/559703">BO DIDDLEY PLAZA</a> / <a target="_blank" href="https://www.seetickets.us/event/Looseys-Taking-Meds-Carly-Cosgrove-and-more/561406">LOOSEY'S</a>
            <br /><br />
          */}


          {/* 
            <a target="_blank" href="https://twitter.com/thefestfl/status/1649160111628091393"> SALES TEMP UNAVAILABLE - WEB STORE SERVER ISSUES</a><br />
            <a target="_blank" href="https://twitter.com/thefestfl">REFRESH OUR SOCIALS FOR THE NEW ONSALE TIME</a><br />
            
            <br />
            DIRECT PURCHASE LINKS: <br /><br />
            <a target="_blank" href="https://wl.seetickets.us/wafform.aspx?_act=eventtickets&_pky=550547&afflky=TheFest">3 DAY PASSES @ SEETICKETS.US</a><br /><br />
            */}


          {/*
            <Link to="/what-is-fest">FIRST TIME TO FEST? READ THIS!</Link>
            <Link to="/schedule">SCHEDULE</Link>{"  OR READ THE "}
            <Link to="/faq">
              FAQ
            </Link>
            <Link to="/bandcamp">BANDCAMP FRIDAY: SUPPORT #FEST19 BANDS!</Link>
          */}
          {" "}


          {/*
            <br />

            <br />
            Read the <Link to="/faq">FAQ</Link> for more info!

            FOR ALL YOUR LAST MINUTE QUESTIONS
            <br />
            <br />
            Get The FEST App:{" "}<a
              href="https://apps.apple.com/us/app/fest-21/id6459265079"
              rel="noopener noreferrer"
              target="_blank">iPhone</a> / <a
                href="https://play.google.com/store/apps/details?id=com.chilluminati.fest21"
                rel="noopener noreferrer"
                target="_blank">Android</a>
            <br /><br />
            <a
              href="https://thefestfl.limitedrun.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              #FEST19 3-DAY PASSES<br />AVAILABLE UNTIL 11:59PM THURSDAY!
            </a>
            */}


        </CopyContainer>
      </FlexContainer>
    </NoticeContainer>

  </FullWidthContainer>
);

const fadeInAnimation = keyframes`${fadeIn}`;

const NoticeContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};
  text-transform: uppercase;
  margin: 14px auto;


  a {
    color: #fff;

    &:hover,
    &:active {
      color: rgba(0, 210, 255, 1) !important;
    }
  }

  svg {
    margin: 0 4px;
    height: 14px;
    width: 14px;
    max-height: 14px;
    max-width: 14px;
  }


  /* MEDIA QUERIES FOR RESPONSIVE SIZES */
  ${StyledComponentsBreakpoints.Phone`
    padding: 24px 8px 12px;
    svg {
      margin: 0 2px;
      height: 12px;
      width: 12px;
      max-height: 12px;
      max-width: 12px;
    }
  `};

`;

const FlexContainer = styled.div`
  margin: 0 auto;
  text-align: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const CopyContainer = styled.div`
  flex: 1 1 100%;
  order: 1;
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 24px;
  display: block;

`;

export default connect()(ActiveNoticeComponent);
