import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import styled from "styled-components";

import siteConfig from "../../../data/SiteConfig";

import TopNavigation from "./Navigation";
import NoticeComponent from "./Notice";
import AdComponent from "../Ad";
import SocialMediaComponent from "../SocialMedia";
import Sponsors from "./Sponsors";
import { FixedWidthContainer } from "./ContentContainers";
import Footer from "./Footer";

import { StyledComponentsBreakpoints } from "./Responsive";

class Page extends Component {
  getMetaUrl() {
    const { title } = this.props;
    let baseUrl = siteConfig.siteUrl;
    if (title) {
      baseUrl += `/${title}`;
    }
    return baseUrl;
  }

  getMetaTitle() {
    const { title } = this.props;

    let pageTitle = siteConfig.siteTitle;

    if (title) {
      pageTitle = `${title} | ${pageTitle}`;
    }
    return pageTitle;
  }

  render() {
    const {
      noLogo,
      noNotice,
      noNav,
      noSponsorsTierOne,
      noSponsorsTierTwo,
      noSponsorsTierThree,
      noSocialMedia,
      noFooter,
      isHome,
      title,
      showTitle,
      children
    } = this.props;

    const marginTop = isHome ? -72 : 24;
    
    return (
      <PageContainer>
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:title" content={this.getMetaTitle()} />
          <meta property="og:url" content={this.getMetaUrl()} />
        </Helmet>

        {!noNotice && <NoticeComponent />}

        {!noNav && <TopNavigation noLogo={noLogo} />}

        {(!noSponsorsTierOne || !noSocialMedia) && (
          <PreContent>
            {!noSponsorsTierOne && <Sponsors tier={1} />}

            {!noSocialMedia && <SocialMediaComponent />}
          </PreContent>
        )}

        <MainContentContainer style={{ marginTop }}>
          <FixedWidthContainer>
            {title && showTitle !== false && <PageTitle>{title}</PageTitle>}
          </FixedWidthContainer>

          {children}
        </MainContentContainer>

        {!isHome && (
        <FixedWidthContainer>
          <AdComponent size="small" style={{ marginBottom: "16px" }} />
        </FixedWidthContainer>
)}
        {!noSponsorsTierTwo && <Sponsors tier={2} />}
        {!noSponsorsTierThree && <Sponsors tier={3} />}

        {!noFooter && <Footer />}
      </PageContainer>
    );
  }
}

const PageContainer = styled.div`
  margin-top: 0;

  ${StyledComponentsBreakpoints.Phone`
    margin-top: 72px;
  `};
`;
const PageTitle = styled.h1`
  margin-bottom: 1rem;
`;

const MainContentContainer = styled.main`
  width: 100%;
  margin: 0 auto 48px;
  padding-top: 0;
  z-index: 1;
`;

const PreContent = styled.div`
  padding-top: 0;
`;

const connectedPage = connect()(Page);
export default connectedPage;
